body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.selected {
  background-color: transparent;
}

.selected:hover {
  background: #139da4;
}

.MuiInputBase-fromControl {
  height: 50vh;
}
